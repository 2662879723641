* {
  color: #252626;
}

html,
html * {
  box-sizing: border-box !important;
}

.app {
  display: flex;
  align-items: center;
  height: 100vh;
}
