footer span {
  color: #252626;
}
.copyright {
  display: flex;
  font-size: 16px;
  box-shadow: 0px 10px #163e59 0.15;
  background: var(--navigation-default-bg);
  span {
    color: var(--navigation-default-item-text-color);
    margin-left: 50px;
  }
}
