@import "../../scss/core.scss";

.app {
  display: flex;
  align-items: center;
  height: 100vh;
}

.home {
  border-radius: 20px;
}

.facade {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
}
