.loader {
  width: 100wh;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.493);
  z-index: 999;
  position: fixed;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
