.custom-tr-white th {
  color: rgb(95, 83, 83);
  text-align: center;
}

.home {
  background-color: rgb(255, 255, 255);
  padding: 30px;
}
.home__title {
  width: 100%;
}
.table > tbody > tr > td,
.table > tbody > tr > th {
  height: 60px;
}

.product-card {
  padding: 0;
  margin: 0;
  border-radius: 10px;
  position: relative;
  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .card-body {
    padding: 15px;
    display: flex;
    flex-direction: column;
    .card-text-price {
      position: absolute;
      bottom: 10px;
    }
  }
}

.table > tbody > tr > td,
.table > tbody > tr > th {
  .vismaicon {
    padding-top: 10px;
    display: inline;
    border-radius: 5px;
    background-color: var(--blue-50);
    box-sizing: content-box;
    transition: all 0.2s ease;
    width: 45px;
    &:hover {
      background-color: var(--blue-20);
    }
  }
}
