/* Provide sufficient contrast against white background */
a {
  color: #252626;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.app {
  display: flex;
  align-items: center;
  height: 100vh;
}

body {
  color: #252626;
  overflow-y: overlay;
}
