.test {
  display: none;
  word-wrap: break-word;
  padding: 20px;
  transition: all 0.3s ease-in;
  .card-text-description {
    color: #fff;
  }
  &:hover {
    background-color: var(--navigation-default-bg);
  }
}
.product-card {
  position: relative;
  height: 250px;
  width: 700px;
  transition: all 0.3s ease-in;

  &:hover {
    .test {
      background-color: #445de9c7;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      justify-content: space-between;
      border-radius: inherit;
      .vismaicon {
        padding: 9px;
        border-radius: 5px;
        transition: all 0.2s ease;
        border: 2px solid #fff;
        width: 45px;
        cursor: pointer;
        &:hover {
          background-color: var(--blue-20);
          border-color: transparent;
        }
      }
    }
    .card-text-description {
      color: #fff;
    }
  }
  box-shadow: 8px 10px 36px -2px #e0e0e0;
}

.product-card.col-2 {
  width: 700px;
}

.card-container {
  gap: 30px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
}

.card-img-top {
  max-width: 100%;
  height: auto;
}
.card-body {
  word-wrap: break-word;
  line-height: 2;
}

.card-text-price {
  font-size: 16px;
}
